import { useId, useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { rgba } from "polished";
import type { Coordinates } from "./logic";
import type { ThemeType } from "@sablier/v2-themes";
import { coordinate, fill } from "./logic";

const Wrapper = styled.div<{ xyz: Coordinates }>`
  ${(props) => props.theme.styles.row}
  & {
    --end: ${({ xyz }) => xyz.CIRCUMFERENCE}px;
    justify-content: center;
    width: ${({ xyz }) => _.toSuffix(xyz.SIZE, "px")};
    height: ${({ xyz }) => _.toSuffix(xyz.SIZE, "px")};
  }
`;

const BoxPartial = styled.svg.attrs<{ xyz: Coordinates }>(({ theme, xyz }) => {
  return {
    ...theme.attributes.base,
    viewBox: xyz.VIEWBOX,
  };
})``;

const Box = styled(BoxPartial)<{ xyz: Coordinates }>``;

const CircleBackgroundPartial = styled.circle.attrs<{ xyz: Coordinates }>(
  ({ xyz }) => {
    return {
      cx: xyz.POINT_CENTER_X,
      cy: xyz.POINT_CENTER_Y,
      r: xyz.RADIUS,
      vectorEffect: "non-scaling-stroke",
    };
  },
)``;

const CircleBackground = styled(CircleBackgroundPartial)<{ xyz: Coordinates }>`
  fill: ${({ theme }) => theme.gradients.svgUrl.darkVertical};
  stroke: ${({ theme }) => theme.colors.dark200};
  stroke-width: ${({ xyz }) => _.toSuffix(xyz.STROKE, "px")};
`;

interface ProgressProps {
  gradient?: keyof ThemeType["gradients"]["svgUrl"];
  xyz: Coordinates;
}
// https://stackoverflow.com/questions/29068088/svg-circle-starting-point.
const CircleProgress = styled.circle.attrs<ProgressProps>(({ xyz }) => {
  return {
    cx: xyz.POINT_CENTER_X,
    cy: xyz.POINT_CENTER_Y,
    r: xyz.RADIUS,
    transform: `rotate(-90 ${xyz.POINT_CENTER_Y} ${xyz.POINT_CENTER_Y})`,
    vectorEffect: "non-scaling-stroke",
  };
})<ProgressProps>`
  ${(props) => props.theme.animations.progress}
  & {
    fill: none;
    stroke: ${({ gradient, theme }) =>
      theme.gradients.svgUrl[gradient || "primary"]};
    will-change: stroke-dasharray;
    transition: stroke-dasharray 400ms ease;
    stroke-width: ${({ xyz }) => _.toSuffix(xyz.STROKE, "px")};
    stroke-linecap: round;

    ${(props) => props.theme.medias.maxXS} {
      animation: none;
      stroke-dasharray: var(--progress-to, var(--end, 100));
    }
  }
`;

const CircleColor = styled(CircleProgress)<{ filled?: number }>`
  stroke-dasharray: 0 0;
  ${(props) => props.theme.medias.maxXS} {
    animation: none;
    stroke-dasharray: var(--progress-to, var(--end, 100));
  }
`;

const CircleElapsed = styled(CircleProgress)`
  ${(props) => props.theme.animations.progressFade}
  & {
    stroke: ${({ theme }) => theme.colors.dark500};
    will-change: stroke-dasharray;
    animation: none;
    stroke-width: ${({ xyz }) => _.toSuffix(xyz.STROKE, "px")};
    stroke-dasharray: ${({ xyz }) => `${xyz.ELAPSED}, ${xyz.CIRCUMFERENCE}`},
      var(--end, 100);

    ${(props) => props.theme.medias.maxXS} {
      animation: none;
    }
  }
`;

const CirclePlaceholder = styled(CircleProgress)`
  ${(props) => props.theme.animations.progressFade}
  & {
    stroke: ${({ theme }) => rgba(theme.colors.dark300, 0.75)};
    stroke-width: ${({ xyz }) => _.toSuffix(xyz.STROKE, "px")};
    stroke-dasharray: none;
    will-change: stroke-dasharray;

    &[data-animated="false"] {
      stroke: ${({ theme }) => theme.colors.dark250};
      animation: none;
    }

    ${(props) => props.theme.medias.maxXS} {
      animation: none;
      stroke-dasharray: none;
    }
  }
`;

const CircleStrokeOuter = styled(CircleProgress).attrs<ProgressProps>(
  ({ xyz }) => {
    return {
      r: xyz.RADIUS_OUTER,
    };
  },
)`
  & {
    --end: ${({ xyz }) => xyz.CIRCUMFERENCE_OUTER};
    stroke: ${({ theme }) => theme.colors.transparent};
    stroke-width: 2px;
    stroke-dasharray: none;
  }
`;

const CircleStrokeInner = styled(CircleStrokeOuter).attrs<ProgressProps>(
  ({ xyz }) => {
    return {
      r: xyz.RADIUS_INNER,
    };
  },
)`
  & {
    --end: ${({ xyz }) => xyz.CIRCUMFERENCE_INNER};
  }
`;

interface Props {
  className?: string;
  elapsed?: number;
  gradient?: keyof ThemeType["gradients"]["svgUrl"];
  isOngoing?: boolean;
  progress: number;
  size: number;
}

function ProgressCircle({
  className,
  elapsed = 0,
  gradient = "primary",
  isOngoing = true,
  progress,
  size,
}: Props) {
  const raw = useId();
  const id = useMemo(
    () =>
      "id".concat(
        _.toString(raw)
          .split("")
          .map((i) => i.charCodeAt(0))
          .join(""),
      ),
    [raw],
  );

  const coordinates = useMemo(() => coordinate(size, elapsed), [elapsed, size]);
  const filled = useMemo(() => fill(size, progress), [size, progress]);

  return (
    <Wrapper className={className} xyz={coordinates}>
      <Box xyz={coordinates} height={coordinates.SIZE} width={coordinates.SIZE}>
        <CircleBackground xyz={coordinates} />
        <CircleStrokeOuter xyz={coordinates} />
        <CircleStrokeInner xyz={coordinates} />
        <CirclePlaceholder data-animated={isOngoing} xyz={coordinates} />
        {elapsed ? <CircleElapsed xyz={coordinates} /> : false}
        <div
          dangerouslySetInnerHTML={{
            __html: `
            <style>
            ${CircleColor}.${id}{
              --progress-to: ${filled}, ${coordinates.CIRCUMFERENCE};
            }
          </style>
        `,
          }}
        />

        <CircleColor className={id} gradient={gradient} xyz={coordinates} />
      </Box>
    </Wrapper>
  );
}

export default ProgressCircle;

import { Fragment, useEffect, useState } from "react";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";

interface Props {
  on: boolean;
  delay?: [number, number];
}

function Conditional({
  children,
  delay = [0, 0],
  on,
}: PropsWithChildren<Props>) {
  const [condition, setCondition] = useState(false);

  useEffect(() => {
    const abort = new AbortController();

    (async () => {
      if (on === true && delay[0]) {
        await _.sleep(delay[0]);
      } else if (on === false && delay[1]) {
        await _.sleep(delay[1]);
      }

      if (!abort.signal.aborted) {
        setCondition(on);
      }
    })();
    return () => {
      abort.abort();
    };
  }, [condition, delay, on]);

  if (condition) {
    return <Fragment>{children}</Fragment>;
  }
  return false;
}

export default Conditional;

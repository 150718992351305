export function coordinate(size: number, elapsed = 0) {
  const STROKE = 12;
  const SPACER = STROKE * 2;

  const SIZE = size;
  const POINT_CENTER_X = SIZE / 2;
  const POINT_CENTER_Y = SIZE / 2;

  const DIAMETER = size - SPACER;
  const RADIUS = DIAMETER / 2;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
  const ELAPSED = elapsed ? (CIRCUMFERENCE * elapsed) / 100 : 0;

  const VIEWBOX = `0 0 ${SIZE} ${SIZE}`;

  const RADIUS_INNER = RADIUS - STROKE / 2;
  const RADIUS_OUTER = RADIUS + STROKE / 2;

  const CIRCUMFERENCE_INNER = 2 * Math.PI * RADIUS_INNER;
  const CIRCUMFERENCE_OUTER = 2 * Math.PI * RADIUS_OUTER;

  return {
    CIRCUMFERENCE,
    CIRCUMFERENCE_INNER,
    CIRCUMFERENCE_OUTER,
    DIAMETER,
    ELAPSED,
    POINT_CENTER_X,
    POINT_CENTER_Y,
    RADIUS,
    RADIUS_INNER,
    RADIUS_OUTER,
    SIZE,
    SPACER,
    STROKE,
    VIEWBOX,
  };
}

export function fill(size: number, progress: number): number {
  const { CIRCUMFERENCE } = coordinate(size);

  const FILLED = (CIRCUMFERENCE * progress) / 100;

  return FILLED;
}

export type Coordinates = ReturnType<typeof coordinate>;

import { memo } from "react";
import styled from "styled-components";
import { StreamStatus } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { ProgressCircle } from "~/components/atoms";

const Layer = styled.div<{ z?: number }>`
  position: absolute;
  z-index: ${(props) => props.z || 10};
`;

const Shadow = styled.div<{ size: number }>`
  width: ${(props) => _.toSuffix(props.size, "px")};
  height: ${(props) => _.toSuffix(props.size, "px")};
  border-radius: calc(${(props) => _.toSuffix(props.size, "px")} / 2);
  background-color: transparent;
  box-shadow: 0px 0px 48px -12px ${(props) => props.theme.colors.dark050};
`;

interface Props {
  gap: number;
  elapsed: number;
  size: number;
  streamed: number;
  withdrawn: number;
  status: StreamStatus;
}

function Progress({ gap, elapsed, size, status, streamed, withdrawn }: Props) {
  if (
    status === StreamStatus.CANCELED ||
    status === StreamStatus.DEPLETED_CANCELED
  ) {
    return (
      <>
        <Layer z={5}>
          <Shadow size={size} />
        </Layer>
        <Layer z={10}>
          <ProgressCircle
            elapsed={elapsed}
            gradient={"red"}
            progress={streamed}
            size={size}
            isOngoing={false}
          />
        </Layer>
        <Layer z={20}>
          <ProgressCircle
            gradient={"primary"}
            progress={withdrawn}
            size={size - gap}
            isOngoing={false}
          />
        </Layer>
      </>
    );
  }

  return (
    <>
      <Layer z={5}>
        <Shadow size={size - 30} />
      </Layer>
      <Layer z={10}>
        <ProgressCircle
          elapsed={elapsed}
          gradient={"secondary"}
          progress={streamed}
          size={size}
        />
      </Layer>
      <Layer z={20}>
        <ProgressCircle
          gradient={"primary"}
          progress={withdrawn}
          size={size - gap}
        />
      </Layer>
    </>
  );
}

export default memo(Progress);

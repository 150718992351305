import { useMemo } from "react";
import styled from "styled-components";
import { StreamStatus } from "@sablier/v2-constants";
import { useWindowSize } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import type { IToken } from "@sablier/v2-types";
import Asset from "./Asset";
import Display from "./Display";
import Progress from "./Progress";

const theme = getTheme();

const Wrapper = styled.div<{ size: number }>`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    justify-content: center;
    width: ${(props) => _.toSuffix(props.size, "px")};
    height: ${(props) => _.toSuffix(props.size, "px")};
  }
`;

const Sizer = styled.div<{ size: number }>`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    justify-content: center;
    width: ${(props) => _.toSuffix(props.size, "px")};
    height: ${(props) => _.toSuffix(props.size, "px")};
  }
`;
const PRESET_WIDTH = theme.sizes.streamCircle;

function useSizes() {
  const { width } = useWindowSize();

  return useMemo(() => {
    if (width < parseInt(theme.sizes.deviceSM, 10)) {
      return { gap: 60, offset: theme.sizes.streamCircleOffset / 2 };
    }
    return { gap: 80, offset: theme.sizes.streamCircleOffset };
  }, [width]);
}

function zeroed(isLoading: boolean, value: number | undefined) {
  return !isLoading && value ? value : 0;
}

interface Props {
  /** Progress (percentage) of the streamed amount out of the total deposited amount */
  cliffAmount?: string;
  cliffProgress?: number;
  isCliffing?: boolean;
  isLoading?: boolean;
  size: number;
  status?: StreamStatus;
  /** Value of the streamed amount*/
  streamedAmount?: string | undefined;
  /** Progress (percentage) of the streamed amount out of the total deposited amount */
  streamedAmountProgress?: number;
  /** Progress (percentage) of the elapsed duration out of the total duration */
  streamedDurationProgress?: number;
  token?: Partial<IToken>;
  /** Total deposited amount */
  totalAmount?: string | undefined;
  /** Progress (percentage) of the withdrawn amount out of the total deposited amount */
  withdrawnAmountProgress?: number;
}

function StreamCircle({
  cliffAmount = undefined,
  isCliffing = false,
  isLoading = false,
  size = PRESET_WIDTH,
  status = StreamStatus.STREAMING,
  streamedAmount = undefined,
  streamedAmountProgress = 0,
  streamedDurationProgress = 0,
  token = {},
  totalAmount = undefined,
  withdrawnAmountProgress = 0,
}: Props) {
  const { gap, offset } = useSizes();

  const elapsed = useMemo(
    () => zeroed(isLoading, streamedDurationProgress),
    [isLoading, streamedDurationProgress],
  );
  const streamed = useMemo(
    () => zeroed(isLoading, streamedAmountProgress),
    [isLoading, streamedAmountProgress],
  );
  const withdrawn = useMemo(
    () => zeroed(isLoading, withdrawnAmountProgress),
    [isLoading, withdrawnAmountProgress],
  );

  if (!size) {
    return false;
  }

  return (
    <Wrapper size={size}>
      <Sizer size={size}>
        <Progress
          gap={gap}
          elapsed={elapsed}
          size={size - offset}
          streamed={streamed}
          withdrawn={withdrawn}
          status={status}
        />
        <Display
          cliffAmount={cliffAmount}
          decimals={token.decimals}
          isCliffing={isCliffing}
          isReady={!isLoading}
          streamed={streamedAmount}
          status={status}
        />
        <Asset isReady={!isLoading} token={token} total={totalAmount} />
      </Sizer>
    </Wrapper>
  );
}

export default StreamCircle;

import styled, { keyframes } from "styled-components";
import { DigitPurpose } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
`;

const moveIn = keyframes`
  0%{
    opacity: 0.9;
    transform: translate(0, 0px);
  }
  100%{
    opacity: 1;
    transform: translate(0, 0px);
  }
`;

const Digit = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    & > p {
      z-index: 5;
      color: ${(props) => props.theme.colors.white};
      font-weight: 700;
      font-size: 29pt;
      font-family: ${(props) => props.theme.fonts.monospace};
      opacity: 1;

      animation: ${moveIn} 250ms ease-in;
      animation-fill-mode: forwards;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-purpose="${DigitPurpose.SUFFIX}"] {
    ${Digit} {
      opacity: 0.2;
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Digit} {
      p {
        font-size: 22pt;
      }
    }
  }
`;

interface Props {
  purpose: DigitPurpose;
  value: string;
}

function Mantissa({ purpose, value }: Props) {
  return (
    <Wrapper data-purpose={purpose}>
      <Digit key={value}>
        <p>{value}</p>
      </Digit>
    </Wrapper>
  );
}

export default Mantissa;

import styled from "styled-components";
import {
  ChevronDoubleRightIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { DigitPurpose, StreamStatus } from "@sablier/v2-constants";
import { useMachineAmount } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { rgba } from "polished";
import { Icon, Tooltip } from "~/components/molecules";
import Characteristic from "./Characteristic";
import Mantissa from "./Mantissa";

const WrapperPartial = styled.div<{ z?: number }>`
  position: absolute;
  z-index: ${(props) => props.z || 10};
  width: 100%;
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    justify-content: center;
    width: 100%;
    height: ${(props) => props.theme.sizes.streamCircleDisplay};
  }
`;

const Background = styled.div`
  --highlight: ${(props) => props.theme.colors.dark1000};
  --end: ${(props) => props.theme.colors.dark500};

  &:before {
    ${(props) => props.theme.styles.shinny}
    & {
      --bottom: -2px;
      width: 40%;
    }
  }

  &:after {
    ${(props) => props.theme.styles.shinny}
    & {
      --top: -2px;
      width: 40%;
    }
  }
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid ${(props) => rgba(props.theme.colors.dark500, 1)};
    border-radius: 14px;
    background-color: ${(props) => rgba(props.theme.colors.dark500, 0.4)};
    backdrop-filter: blur(7.5px);
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: 20;
    justify-content: center;
    align-items: flex-end;
    width: 100%;

    &[data-loading="true"] {
      visibility: hidden;
    }
  }
`;

const Locker = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 25;
`;

const Lock = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 20px;
    position: relative;
    justify-content: center;
    width: 36px;
    height: 36px;
    color: ${(props) => props.theme.colors.yellow};
    border: 2px solid ${(props) => rgba(props.theme.colors.dark500, 1)};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark150};
    backdrop-filter: blur(5.5px);
  }
`;

const About = styled.p`
  max-width: 180px;
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    ${Box} {
      height: auto;
      padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
    }

    ${Locker} {
      bottom: -22px;
      right: 6px;
      ${Lock} {
        border-radius: 50%;
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Box} {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    }
    ${Background} {
      border-radius: 8px;
    }
  }
`;
interface Props {
  cliffAmount?: string | undefined;
  decimals: number | undefined;
  isCliffing: boolean | undefined;
  isReady: boolean | undefined;
  streamed: string | undefined;
  status: StreamStatus;
}

function Indicator({ isCliffing, cliffAmount, status }: Partial<Props>) {
  const { t } = useT();

  if (status === StreamStatus.PENDING) {
    return (
      <Locker>
        <Tooltip value={<About>{t("structs.availableAfterStart")}</About>}>
          <Lock>
            <Icon value={ChevronDoubleRightIcon} />
          </Lock>
        </Tooltip>
      </Locker>
    );
  }

  if (isCliffing) {
    return (
      <Locker>
        <Tooltip
          value={
            <About>
              {t("structs.availableAfterCliff", { amount: cliffAmount })}
            </About>
          }
        >
          <Lock>
            <Icon value={LockClosedIcon} />
          </Lock>
        </Tooltip>
      </Locker>
    );
  }

  return false;
}

function Display({
  cliffAmount = undefined,
  isCliffing = false,
  isReady = true,
  streamed,
  status,
}: Props) {
  const definition = useMachineAmount(streamed || "0", status);

  return (
    <Wrapper z={40}>
      <Box>
        <Background />
        <Content data-loading={!isReady}>
          {definition.map((item, index) => {
            switch (item.p) {
              case DigitPurpose.ABBREVIATION:
              case DigitPurpose.CHARACTERISTIC:
              case DigitPurpose.PREFIX:
                return (
                  <Characteristic key={index} purpose={item.p} value={item.d} />
                );
              case DigitPurpose.DIVIDER:
              case DigitPurpose.MANTISSA:
              case DigitPurpose.SUFFIX:
                return <Mantissa key={index} purpose={item.p} value={item.d} />;
              default:
                return false;
            }
          })}
        </Content>
        <Indicator
          cliffAmount={cliffAmount}
          isCliffing={isCliffing}
          status={status}
        />
      </Box>
    </Wrapper>
  );
}

export default Display;

import { memo, useMemo } from "react";
import styled from "styled-components";
import { useChainExplorer } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IToken } from "@sablier/v2-types";
import { External } from "~/components/atoms";
import { Token } from "~/components/molecules";

const WrapperPartial = styled.div<{ z?: number }>`
  position: absolute;
  z-index: ${(props) => props.z || 10};
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 112px 0;
  }
`;

const Top = styled(External)`
  ${(props) => props.theme.styles.row}
  & {
    --size: 42px;
    position: relative;
    justify-content: center;

    & > * {
      position: relative;
      z-index: ${(props) => props.theme.sizes.zIndexOver};

      &:after {
        position: absolute;
        content: "";
        z-index: 1;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0);
        opacity: 1;
        transform: scale(1);
      }

      &:hover,
      &:active {
        &:after {
          background-color: rgba(255, 255, 255, 0.5);
          opacity: 0;
          transform: scale(1.8);
          transition: transform 500ms, opacity 400ms, background-color 300ms;
        }
      }
      & > * {
        position: relative;
        z-index: 3;
      }
    }
  }
`;

const Bottom = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-start;
    height: 38px;

    & > p {
      ${(props) => props.theme.styles.textElement}
      & {
        color: ${(props) => props.theme.colors.gray100};
        font-weight: 700;
        font-size: 13pt;
        span {
          color: ${(props) => props.theme.colors.gray100};
        }
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    ${Container} {
      row-gap: 160px;
      justify-content: center;
      padding: 0;
    }
    ${Top} {
      --size: 36px;
    }
    ${Bottom} {
      align-items: center;
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Container} {
      row-gap: 110px;
    }
    ${Top} {
      --size: 26px;
    }
    ${Bottom} {
      & > p {
        font-size: 10pt;
      }
    }
  }
  ${(props) => props.theme.medias.maxXXS} {
    ${Container} {
      row-gap: 90px;
    }
    ${Top} {
      --size: 24px;
    }
  }
`;

interface Props {
  isReady?: boolean;
  token: Partial<IToken>;
  total?: string;
}

function Asset({ isReady = true, token, total }: Props) {
  const { t } = useT();
  const { address, chainId, image, symbol } = useMemo(
    () => token || {},
    [token],
  );
  const bottom = useMemo(() => {
    const formatted = !_.isNilOrEmptyString(total)
      ? _.toNumeralPrice(total)
      : "";

    const partToken = !_.isNilOrEmptyString(symbol)
      ? `${symbol.toUpperCase()} `
      : "";
    const partAmount = !_.isNilOrEmptyString(formatted) ? `${formatted}` : "";
    return `${t("structs.outOf")} `.concat(partToken).concat(partAmount);
  }, [symbol, total, t]);

  const explorer = useChainExplorer({ chainId, hash: address, type: "token" });

  return (
    <Wrapper z={30}>
      <Container>
        <Top to={explorer}>
          <Token
            empty={"dark300"}
            image={isReady ? image : undefined}
            isFallbackShown={isReady && !_.isNilOrEmptyString(address)}
          />
        </Top>
        <Bottom>{isReady && <p>{bottom}</p>}</Bottom>
      </Container>
    </Wrapper>
  );
}

export default memo(Asset);

import { useEffect, useMemo } from "react";
import { amount } from "@sablier/v2-machines";
import { useMachine } from "@xstate/react";
import type { StreamStatus } from "@sablier/v2-constants";

function useMachineAmount(source: string, status: StreamStatus | undefined) {
  const [currentMachine, sendToMachine] = useMachine(amount);

  const definition = useMemo(() => {
    return currentMachine.context.computed.definition;
  }, [currentMachine.context.computed]);

  useEffect(() => {
    sendToMachine("FORMAT", { source, status });
  }, [sendToMachine, source, status]);

  return definition;
}

export default useMachineAmount;
